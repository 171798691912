import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { TextField, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import neighborhoods from '../asset/neighborhoods.js';
import xtownCity from '../asset/xtown-city.svg';
import '../styles/Manage.css';
import Header from "./Header.js";
import http from '../http.js';

const Input = props => (
    <TextField
        error={ props.error }
        disabled={ props.disabled }
        label= { props.label }
        value={ props.value }
        onChange={ props.onChangeFunc }
        helperText={ props.error ? props.errMsg : props.normalMsg}
        variant="outlined"
        style = {{width: '500px'}}
        margin="normal"
        size="small"
    />
);

export default function App () {
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
    const [selectedNeighborhoodsErr, setSelectedNeighborhoodsErr] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [failureSnackbarOpen, setFailureSnackbarOpen] = useState(false);
    const [unsubscribeUser, setUnsubscribeUser] = useState(false);

    const query = new URLSearchParams(useLocation().search);
    let email = atob(query.get("account"));
    let encodedEmail = btoa(email);

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSuccessSnackbarOpen(false);
    };

    const handleFailureSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setFailureSnackbarOpen(false);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const result = await http.post('/info', { email: email });
                console.log(result);
                if (!result.data.name) {
                    setUnsubscribeUser(true);
                } else {
                    let neighborhoodList = [];
                    setName(result.data.name);
                    result.data.neighborhoods.forEach(d => {
                        const index = neighborhoods.NEIGHBORHOODS.map(nb => nb.id).indexOf(d);
                        neighborhoodList.push(neighborhoods.NEIGHBORHOODS[index])
                    })
                    setSelectedNeighborhoods(neighborhoodList);
                }
            } catch(e) {
                console.log(e);
            }
        }
        fetchData();
    }, [email]);

    async function handleChange(e) {
        e.preventDefault();
        if (!name.trim() || 
            selectedNeighborhoods.length === 0) {
            if (!name.trim()) setNameErr(true);
            else setNameErr(false);
            if (selectedNeighborhoods.length === 0) setSelectedNeighborhoodsErr(true);
            else setSelectedNeighborhoodsErr(false);
            setFailureSnackbarOpen(true);
        } else {
            setNameErr(false);
            setSelectedNeighborhoodsErr(false);
            setSuccessSnackbarOpen(true);
            
            var data = {
                name: name,
                email: email,
                neighborhoods: selectedNeighborhoods
            }

            const response = http.post('/update', data)
            console.log(response.data);
        }
    }

    if (unsubscribeUser) {
        return (
            <div>
            <Header />
            <div className="Unsubscribe">
                <img src={xtownCity} alt="Logo" />
                <h1> Hey! You've already unsubscribed. </h1>
                <p> You can always subscribe again in the future. We'll be there.</p>
                <p> In the mean time, feel free to check out our website and/or follow us on </p>
                <p> social media to get updates on Los Angeles. </p>
                <br></br>
                <Button 
                    style = {{width: '230px'}}
                    className="manageButton"
                    variant="contained" 
                    color="primary"
                    href="https://xtown.la/"
                    disableElevation
                    >
                    Explore Crosstown &ensp;<ArrowForwardIcon />
                </Button>
                <div className="socialMediaIcons">
                    <TwitterIcon color='primary' fontSize='large'/>
                    <InstagramIcon color='primary' fontSize='large'/>
                    <FacebookIcon color='primary' fontSize='large'/>
                </div>
            </div>
        </div>
        )
    }

    return (
        <div>
            <Header />
            <div className="left-form">
                <h1> Manage Subscriptions </h1>
                Make sure to click the button below to save your preferences.
                <br></br>
                <form noValidate autoComplete="off">
                    <Input 
                        label="Email Address"
                        value={ email } 
                        errMsg="Please enter a valid email address"
                        normalMsg=""
                        disabled={true}
                    />
                    <br></br>
                    <Input 
                        error={nameErr} 
                        label="First Name" 
                        value={ name } 
                        onChangeFunc={e=>setName(e.target.value)}
                        errMsg="Please enter a first name"
                        normalMsg=""
                        disabled={false}
                    />
                    <Autocomplete
                        multiple
                        options={neighborhoods.NEIGHBORHOODS}
                        getOptionLabel={(option) => option.name}
                        value={ selectedNeighborhoods }
                        onChange={
                            (e, input) => { setSelectedNeighborhoods(input);}
                        }
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            error={selectedNeighborhoodsErr}
                            variant="outlined"
                            label="Neighborhoods"
                            margin="normal"
                            size="small"
                            style = {{width: '500px'}}
                            helperText={selectedNeighborhoodsErr ? "Please enter at least one neighborhood" : ""}
                        />
                        )}
                    />
                    <br></br>
                    <br></br>
                    <div className="options">
                        <Button 
                            style = {{width: '200px'}}
                            className="manageButton"
                            variant="contained" 
                            color="primary"
                            onClick={handleChange}
                            disableElevation
                            >
                            Save My Preferences
                        </Button>
                        <span className="manageText">&ensp; or <Link className="unsubscribeLink" to={`/unsubscribe?account=${encodedEmail}`}>Unsubscribe</Link></span>
                    </div>
                </form>
            </div>
            <Snackbar 
              anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
              open={successSnackbarOpen} 
              autoHideDuration={5000} 
              onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} severity="success">
                <b>Well done!</b> You successfully saved your changes!
                </Alert>
            </Snackbar>
            <Snackbar 
              anchorOrigin={{vertical: 'top', horizontal: 'center'}} 
              open={failureSnackbarOpen} 
              autoHideDuration={5000} 
              onClose={handleFailureSnackbarClose}>
                <Alert onClose={handleFailureSnackbarClose} severity="error">
                <b>Oh Snap! <u>Change a few things up</u></b> and try submitting again.
                </Alert>
            </Snackbar>
        </div> 
    );
}