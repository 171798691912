import React, { useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import {
    TextField,
    Button,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import logo from '../asset/logo-round.svg';
import neighborhoods from '../asset/neighborhoods.js';
import '../styles/Signup.css';
import PrivacyPolicy from "./PrivacyPolicy";
import ClientCaptcha from "react-client-captcha"
import "react-client-captcha/dist/index.css"
import http from '../http.js';

function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

const Input = props => (
    <TextField
        error={props.error}
        label={props.label}
        value={props.value}
        onChange={props.onChangeFunc}
        helperText={props.error ? props.errMsg : props.normalMsg}
        variant="outlined"
        style={{ width: '400px' }}
        margin="normal"
        size="small"
    />
);

const useStyles = makeStyles((theme) => ({
    paper: {
        //   position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function App() {
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
    const [selectedNeighborhoodsErr, setSelectedNeighborhoodsErr] = useState(false);
    const [policyOpen, setPolicyOpen] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [captchaCode, setCaptchaCode] = useState("");
    const [captchaEntered, setCaptchaEntered] = useState('');
    const [captchaErr, setCaptchaErr] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null); // This tracks success or error status

    async function handleClick(e) {
        e.preventDefault();
        if (!name.trim() ||
            !validateEmail(email.trim()) ||
            selectedNeighborhoods.length === 0 ||
            captchaEntered !== captchaCode.captchaCode) {
            if (!name.trim()) setNameErr(true);
            else setNameErr(false);
            if (!validateEmail(email.trim())) setEmailErr(true);
            else setEmailErr(false);
            if (selectedNeighborhoods.length === 0) setSelectedNeighborhoodsErr(true);
            else setSelectedNeighborhoodsErr(false);
            if (captchaEntered !== captchaCode.captchaCode) {
                setCaptchaErr(true);
            } else {
                setCaptchaErr(false);
            }
        } else {
            setConfirmation(true);
            var data = {
                name: name,
                email: email,
                neighborhoods: selectedNeighborhoods
            }
            try {
                const result = await http.post("/subscribe", data);
                //console.log(result);
                setSubscriptionStatus(result.data.success ? 'success' : 'error'); // Set success status if successful
            } catch (e) {
                console.log(e);
                setSubscriptionStatus('error'); // Set error status if there's a problem
            };
        }
    }

    const handlePolicyOpen = () => {
        setPolicyOpen(true);
    };

    const handlePolicyClose = () => {
        setPolicyOpen(false);
    };

    const setCode = captchaCode => {
        setCaptchaCode({ captchaCode });
    }

    const classes = useStyles();
    const DialogBody = (
        <div className={classes.paper}>
            <DialogTitle id="simple-modal-title"></DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="scroll-dialog-description"
                >
                    <PrivacyPolicy />
                </DialogContentText>
            </DialogContent>
        </div>
    );

    return (
        <div className="App">
            {!confirmation ?
                <div className="left-form">
                    <img className="logo" src={logo} alt="Logo" />
                    <h2>Sign up for The Crosstown <br />Neighborhood Newsletter </h2>
                    <span>Receive data-driven stories about your neighborhood <br /> delivered weekly.</span>
                    <form noValidate autoComplete="off">
                        <Input
                            error={nameErr}
                            label="First Name"
                            value={name}
                            onChangeFunc={e => setName(e.target.value)}
                            errMsg="Please enter a first name"
                            normalMsg=""
                        />
                        <br />
                        <Input
                            error={emailErr}
                            label="Email Address"
                            value={email}
                            onChangeFunc={e => setEmail(e.target.value)}
                            errMsg="Please enter a valid email address"
                            normalMsg=""
                        />
                        <Autocomplete
                            multiple
                            options={neighborhoods.NEIGHBORHOODS}
                            getOptionLabel={(option) => option.name}
                            defaultValue={[neighborhoods.NEIGHBORHOODS[22]]}
                            value={selectedNeighborhoods}
                            onChange={
                                (e, input) => { setSelectedNeighborhoods(input); }
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={selectedNeighborhoodsErr}
                                    variant="outlined"
                                    label="Neighborhoods"
                                    margin="normal"
                                    size="small"
                                    style={{ width: '400px' }}
                                    helperText={selectedNeighborhoodsErr ? "Please enter at least one neighborhood" : ""}
                                />
                            )}
                        />
                        <br />
                        <div className="captchaDiv">
                            <ClientCaptcha captchaCode={setCode} />
                        </div>
                        <Input
                            error={captchaErr}
                            label="Type the characters seen in the image above"
                            value={captchaEntered}
                            onChangeFunc={e => setCaptchaEntered(e.target.value)}
                            errMsg="Characters did not match the ones in the image above"
                            normalMsg=""
                        />
                        <br />
                        <Button
                            style={{ width: '400px' }}
                            className="signUpButton"
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                            disableElevation
                        >
                            Sign Up
                        </Button>
                        <p style={{ fontSize: '12px' }}>By providing your email, you agree to the&nbsp;
                            <Link href="#" onClick={handlePolicyOpen}>
                                Crosstown Privacy Policy.
                            </Link>
                        </p>
                    </form>
                    <Dialog
                        scroll='paper'
                        maxWidth='md'
                        open={policyOpen}
                        onClose={handlePolicyClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {DialogBody}
                    </Dialog>
                    <p style={{ fontSize: '12px' }}> © 2020-24 XTOWN Media </p>
                </div>
                :
                <div className="left-form">
                    <Button className="crosstownNav" variant="outlined" color="primary" href="https://xtown.la/"> Back to Crosstown </Button>
                    <EmailIcon color="primary" fontSize="large" />
                    {subscriptionStatus === null ? (
                        <>
                            <h2> Waiting for confirmation... </h2>
                            <p> Please wait while we process your subscription. </p>
                        </>
                    ) : subscriptionStatus === 'success' ? (
                        <>
                            <h2> You've been subscribed </h2>
                            <p> Look out for your welcome email. Be sure to check spam just in case! </p>
                        </>
                    ) : (
                        <>
                            <h2> We are sorry </h2>
                            <p> There was an issue with your subscription. Please try again later. </p>
                        </>
                    )}
                  <p style={{ fontSize: '12px' }}> © 2020-24 XTOWN Media </p>
                </div>
            }
            <div className="hero-image-container">
            <picture>
        <source  srcSet={`${process.env.PUBLIC_URL}/tablet.svg`} media="(max-width:800px)"  />
        <img className="hero-image" src={`${process.env.PUBLIC_URL}/desktop.svg`} alt='backgound-image'/>
    </picture>            </div>
        </div>
    );
}