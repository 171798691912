import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

console.log('process.env' + JSON.stringify(process.env))
console.log('REACT_APP_API_BASE_URL' + baseURL)

const axiosInstance = axios.create({
  baseURL: baseURL,
});

export default axiosInstance;
