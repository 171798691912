import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Signup from "./components/Signup";
import Manage from "./components/Manage";
import Unsubscribe from "./components/Unsubscribe";

export default function App() {
    return (
        <Router basename="/">
            <Switch>
                <Route path="/" component={Signup} exact />
                <Route path="/manage" component={Manage} />
                <Route path="/unsubscribe" component={Unsubscribe} />
            </Switch>
        </Router>
    )
}
